import logo from './logo.svg';
import './App.css';

import _ from 'lodash';
import {
  endOfMonth,
  format,
  getMonth,
  setMonth,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
} from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import netlifyIdentity from 'netlify-identity-widget';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const currencyValueFormatter = ({ value }) => currencyFormatter.format(value);

const columns = [
  {
    field: 'createdAt',
    headerName: 'Timestamp',
    width: 200,
    type: 'dateTime',
  },
  { field: 'side', headerName: 'Side', width: 100 },
  { field: 'size', headerName: 'Size', width: 100, type: 'number' },
  {
    field: 'price',
    headerName: 'Price',
    width: 120,
    type: 'number',
    valueFormatter: currencyValueFormatter,
  },
  {
    field: 'fee',
    headerName: 'Fee',
    width: 100,
    type: 'number',
    valueFormatter: currencyValueFormatter,
  },
  {
    field: 'feePercent',
    headerName: 'Fee %',
    width: 100,
    type: 'number',
    valueGetter: (params) => {
      const fee = params.getValue('fee');
      const price = params.getValue('price');
      const size = params.getValue('size');
      const feePercent = (fee / price / Math.abs(size)) * 100;
      return `${feePercent.toFixed(2)}%`;
    },
  },
  {
    field: 'total',
    headerName: 'Total',
    width: 120,
    type: 'number',
    valueFormatter: currencyValueFormatter,
  },
  {
    field: 'runningTotal',
    headerName: '📈 USD',
    width: 120,
    type: 'number',
    valueFormatter: currencyValueFormatter,
  },
  {
    field: 'runningSize',
    headerName: '📈 BTC',
    width: 100,
    type: 'number',
  },
  {
    field: 'runningFees',
    headerName: '📈 Fees',
    width: 100,
    type: 'number',
    valueFormatter: currencyValueFormatter,
  },
  {
    field: 'runningVolume',
    headerName: '📈 Volume',
    width: 150,
    type: 'number',
    valueFormatter: currencyValueFormatter,
  },
];

function getTotalBalance(data) {
  const { ticker, accounts } = data;

  const btcPrice = Number(ticker.price);

  const totalBalance =
    Number(accounts['BTC'].balance) * btcPrice +
    Number(accounts['USD'].balance);

  return totalBalance;
}

function getRows(data, startDate, endDate, selectedOrders) {
  const { fills, orders } = data;

  const ordersById = _.keyBy(orders, 'id');

  const simulatedOrders = selectedOrders.map((selectedOrderId) => {
    const { side, size, price, id } = ordersById[selectedOrderId];
    return {
      side,
      size,
      price,
      fee: 0,
      usd_volume: Number(size) * Number(price),
      trade_id: id,
    };
  });

  const rows = fills
    .slice()
    .reverse()
    .concat(simulatedOrders)
    .reduce((acc, fill) => {
      const createdAt = fill.created_at && new Date(fill.created_at);
      if (!createdAt || (createdAt > startDate && createdAt < endDate)) {
        const { side } = fill;
        const price = Number(fill.price);
        const fee = Number(fill.fee);

        let total = Number(fill.usd_volume);
        if (side === 'buy') {
          total = -total;
        }
        total -= fee;

        let size = Number(fill.size);
        if (side === 'sell') {
          size = -size;
        }

        let runningTotal = total;
        let runningVolume = Number(fill.usd_volume);
        let runningSize = size;
        let runningFees = fee;
        const last = acc[acc.length - 1];
        if (last) {
          runningTotal += last.runningTotal;
          runningVolume += last.runningVolume;
          runningSize += last.runningSize;
          runningFees += last.runningFees;
        }

        acc.push({
          id: fill.trade_id,
          side,
          createdAt,
          size,
          price,
          fee,
          total,
          runningTotal,
          runningVolume,
          runningSize,
          runningFees,
        });
      }
      return acc;
    }, []);

  return rows.reverse();
}

function App() {
  const queryParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const initialCurrentWindowIndex = queryParams.w ? Number(queryParams.w) : 0;
  const now = new Date();
  const [customDate, setCustomDate] = useState(null);
  const [data, setData] = useState();
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [open, setOpen] = useState(false);
  const [isUsd, setIsUsd] = useState(true);
  const [currentWindowIndex, setCurrentWindowIndex] = useState(
    initialCurrentWindowIndex
  );

  const windows = [];
  const midnightUTC = Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate()
  );
  windows.push({
    name: 'Last 30 Days',
    startDate: subDays(midnightUTC, 30),
    endDate: now,
  });
  const currentMonth = getMonth(now);
  for (let i = currentMonth; i >= 0; i--) {
    const startDate = startOfMonth(setMonth(new Date(), i));
    windows.push({
      name: format(startDate, 'MMM y'),
      startDate,
      endDate: endOfMonth(startDate),
    });
  }
  windows.push({ name: 'YTD', startDate: startOfYear(now), endDate: now });
  windows.push({ name: 'Custom', startDate: customDate, endDate: now });

  let rows;
  let currentProfitString;
  let orders;
  if (data) {
    const totalBalance = getTotalBalance(data);
    console.log({ totalBalance });

    const { startDate, endDate } = windows[currentWindowIndex];
    rows = getRows(data, startDate, endDate, selectedOrders);

    const btcPrice = Number(data.ticker.price);
    const currentUsd = _.sum(rows.map((row) => row.total));
    const currentBtc = _.sum(rows.map((row) => row.size));
    if (isUsd) {
      const currentProfit = currentUsd + currentBtc * btcPrice;
      currentProfitString = currencyFormatter.format(currentProfit);
    } else {
      const currentProfit = currentUsd / btcPrice + currentBtc;
      currentProfitString = `₿${currentProfit.toFixed(4)}`;
    }

    const maxBtc = _.min(_.map(rows, 'runningSize'));
    const maxUsd = _.min(_.map(rows, 'runningTotal'));

    console.log({ maxBtc, maxUsd });

    orders = data.orders;
  }

  const handleToggle = (value) => () => {
    const currentIndex = selectedOrders.indexOf(value);
    const newSelectedOrders = [...selectedOrders];

    if (currentIndex === -1) {
      newSelectedOrders.push(value);
    } else {
      newSelectedOrders.splice(currentIndex, 1);
    }

    setSelectedOrders(newSelectedOrders);
  };

  useEffect(() => {
    netlifyIdentity.init();

    async function fetchData() {
      if (netlifyIdentity.currentUser()) {
        const token = await netlifyIdentity.currentUser().jwt();
        const response = await fetch('/.netlify/functions/fills', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();

        setData(data);
        console.log(data);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    window.history.replaceState(
      null,
      null,
      qs.stringify({ w: currentWindowIndex }, { addQueryPrefix: true })
    );
  }, [currentWindowIndex]);

  function handleDateChange(value) {
    if (!value) {
      setCurrentWindowIndex(0);
      setCustomDate(value);
    } else {
      setCustomDate(startOfDay(value));
    }
  }

  return (
    <div className="App">
      {rows ? (
        <Box flexGrow={1} display="flex" flexDirection="column">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={1}
            pt={1.5}
          >
            {currentWindowIndex === windows.length - 1 ? (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  clearable
                  format="MM/dd/yyyy"
                  value={customDate}
                  onChange={handleDateChange}
                />
              </MuiPickersUtilsProvider>
            ) : (
              <Select
                value={currentWindowIndex}
                onChange={(event) => setCurrentWindowIndex(event.target.value)}
              >
                {windows.map(({ name }, index) => (
                  <MenuItem key={index} value={index}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            )}
            <Box mx={1}>
              <Chip
                label={`Current Profit: ${currentProfitString}`}
                onClick={() => setIsUsd(!isUsd)}
              />
            </Box>
            <Badge badgeContent={orders.length} color="primary">
              <Chip
                label={`Current Price: ${currencyFormatter.format(
                  data.ticker.price
                )}`}
                onClick={orders.length > 0 ? () => setOpen(true) : null}
              />
            </Badge>
            <Dialog open={open} onClose={() => setOpen(false)}>
              <List>
                {orders.map((order) => (
                  <ListItem
                    key={order.id}
                    button
                    onClick={handleToggle(order.id)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedOrders.indexOf(order.id) !== -1}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${order.side} ${Number(
                        order.size
                      )} @ ${currencyFormatter.format(order.price)}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Dialog>
          </Box>
          <Box flexGrow={1} p={1}>
            <DataGrid
              rows={rows}
              columns={columns}
              disableColumnMenu
              disableColumnSelector
              disableSelectionOnClick
            />
          </Box>
        </Box>
      ) : (
        <header className="App-header" onClick={() => netlifyIdentity.open()}>
          <img src={logo} className="App-logo" alt="logo" />
        </header>
      )}
    </div>
  );
}

export default App;
